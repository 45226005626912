import { unset } from 'lodash';
import axios from 'axios';
import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { formSubmitTypeReplace, getDotKeys } from '../../helper';
import { WhitelabelContext } from '../../../components/layout/Default';
import { useNavigation } from '../index';

const useFormActionSubmit = () => {
  const formik = useFormikContext();
  const { navigateTo, reload } = useNavigation();
  const whitelabelContext = useContext(WhitelabelContext);

  return async (action) => {
    if (!action) {
      return;
    }

    whitelabelContext.setIsLoading(true);
    const data = {
      userdata: {
        action,
      },
    };
    if (formik?.values) {
      const { values } = formik;
      if (formik?.errors) {
        const fieldsWithErrors = getDotKeys(formik.errors);
        if (fieldsWithErrors?.length > 0) {
          fieldsWithErrors.forEach((path) => unset(values, path));
        }
      }

      data.userdata = formSubmitTypeReplace({
        ...values,
        action,
      });
    }

    try {
      const res = await axios.post('/api/submit', data);
      if (res?.data.error || !res?.data?.data?.nextPage) {
        reload();
      } else {
        navigateTo(`/page/${res.data.data.nextPage}`);
      }
    } catch (error) {
      reload();
    }
  };
};

export default useFormActionSubmit;
