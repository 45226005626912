import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { get, map } from 'lodash';
import { DataContext } from '../../../components/layout/Default';
import { comparisonTypesFunction } from './fieldComparations';

const useParseConditionalJson = (component) => {
  const { values: formikValues } = useFormikContext();
  const { sessionData } = useContext(DataContext);

  const fieldArrayConditionCheck = (
    propertyPath,
    valueToCompare,
    comparisonFunction,
  ) => {
    const [collectionPath, propertyName] = propertyPath.split('[].');
    if (collectionPath && propertyName) {
      const collection = get(formikValues, collectionPath);
      if (collection?.length) {
        const propertyArray = map(collection, propertyName);
        if (propertyArray?.length) {
          return propertyArray.some((elem) =>
            comparisonFunction(elem, valueToCompare),
          );
        }
      }
    }

    return false;
  };

  const conditionCheck = (condition) => {
    const fieldToCompare = sessionData?.pageData?.components?.find(
      ({ fieldId }) => fieldId === condition.field,
    );
    const conditionValueField = sessionData?.pageData?.components.find(
      ({ fieldId }) => fieldId === condition.value,
    );

    if (!fieldToCompare || !comparisonTypesFunction?.[condition.comparison]) {
      return false;
    }

    const fieldToCompareValue = get(formikValues, fieldToCompare?.field?.value);
    const valueToCompare = get(
      formikValues,
      conditionValueField?.field?.value,
      condition.value,
    );

    const isFieldArray = /^\w+\[][.\w]+/.test(fieldToCompare?.field?.value);

    if (isFieldArray) {
      return fieldArrayConditionCheck(
        fieldToCompare?.field?.value,
        valueToCompare,
        comparisonTypesFunction?.[condition.comparison],
      );
    }

    if (['in', 'not_in'].includes(condition.comparison)) {
      if (!Array.isArray(condition?.valArray)) {
        return false;
      }

      return comparisonTypesFunction?.[condition.comparison](
        fieldToCompareValue,
        condition.valArray,
      );
    }

    if (fieldToCompareValue !== undefined) {
      return comparisonTypesFunction?.[condition.comparison](
        fieldToCompareValue,
        valueToCompare,
      );
    }
    return false;
  };

  const andCheck = (conditions) => {
    if (!Array.isArray(conditions)) {
      return false;
    }

    return conditions.every(({ condition, or: orConditions }) => {
      if (orConditions) {
        // eslint-disable-next-line no-use-before-define
        return orCheck(orConditions);
      }
      return conditionCheck(condition);
    });
  };

  const orCheck = (conditions) => {
    if (!Array.isArray(conditions)) {
      return false;
    }

    return conditions.some(({ condition, and: andConditions }) => {
      if (andConditions) {
        // eslint-disable-next-line no-use-before-define
        return andCheck(andConditions);
      }
      return conditionCheck(condition);
    });
  };

  const checkJSON = (jsonCondition) => {
    try {
      if (jsonCondition?.value === true) {
        return true;
      }

      if (jsonCondition?.condition) {
        return conditionCheck(jsonCondition.condition);
      }

      if (jsonCondition?.and) {
        return andCheck(jsonCondition.and);
      }

      if (jsonCondition?.or) {
        return orCheck(jsonCondition.or);
      }

      return false;
    } catch (e) {
      return false;
    }
  };

  const isRequired = () => {
    if (!component?.required || !formikValues || !sessionData) {
      return false;
    }
    return checkJSON(component.required);
  };
  const isEnabled = () => {
    if (!component?.enabled || !formikValues || !sessionData) {
      return true;
    }

    return checkJSON(component.enabled);
  };
  const isVisible = () => {
    if (!component?.display || !formikValues || !sessionData) {
      return true;
    }

    return checkJSON(component.display);
  };

  return {
    isRequired,
    isEnabled,
    isVisible,
    checkJSON,
  };
};

export default useParseConditionalJson;
