import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import usePrevious from '../usePrevious';

const usePageBuilderFieldMath = (component) => {
  const formik = useFormikContext();
  const prevValues = usePrevious(formik.values);
  const currentFieldName = component.value || component.field.value;

  const isNumberGreaterThanOrEqualZero = (value) =>
    (typeof value === 'number' || value instanceof Number) && value >= 0;

  const getObjectsDiff = (obj1, obj2, currentPath = '') => {
    let diffPaths = [];

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const prop in obj1) {
      const path = currentPath ? `${currentPath}.${prop}` : prop;

      if (typeof obj2[prop] === 'undefined') {
        if (typeof obj1[prop] === 'object') {
          diffPaths = diffPaths.concat(getObjectsDiff(obj1[prop], {}, path));
        } else {
          diffPaths.push(path);
        }
      } else if (
        typeof obj1[prop] === 'object' &&
        typeof obj2[prop] === 'object'
      ) {
        diffPaths = diffPaths.concat(
          getObjectsDiff(obj1[prop], obj2[prop], path),
        );
      } else if (obj1[prop] !== obj2[prop]) {
        diffPaths.push(path);
      }
    }

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const prop in obj2) {
      const path = currentPath ? `${currentPath}.${prop}` : prop;

      if (typeof obj1[prop] === 'undefined') {
        diffPaths.push(path);
      }
    }

    return diffPaths;
  };

  const operations = {
    divide: (values = []) =>
      values.reduce((a, b) => parseFloat(a) / parseFloat(b)),
    subtract: (values = []) =>
      values.reduce((a, b) => parseFloat(a) - parseFloat(b)),
    multiply: (values = []) =>
      values.reduce((a, b) => parseFloat(a) * parseFloat(b)),
    sum: (values = []) =>
      values.reduce((a, b) => parseFloat(a) + parseFloat(b)),
    arrsum: (values = []) =>
      values.reduce((a, b) => parseFloat(a) + parseFloat(b)),
  };

  const getMathItemFieldValue = (fieldName, fieldNameChanged) => {
    const isFieldArray = /^\w+\[][.\w]+/.test(fieldName);
    let formikFieldValue = 0;

    if (isFieldArray && /\b\d+(\.\d+)?\b/.test(fieldNameChanged)) {
      const [fieldIndex] = `${fieldNameChanged}`.match(/\b\d+(\.\d+)?\b/);
      if (fieldIndex >= 0) {
        const formikFieldPath = fieldName.replace('[]', `.${fieldIndex}`);
        formikFieldValue = parseFloat(
          get(formik.values, formikFieldPath, undefined),
        );
      }
    } else {
      formikFieldValue = parseFloat(get(formik.values, fieldName, undefined));
    }

    return isNumberGreaterThanOrEqualZero(formikFieldValue)
      ? formikFieldValue
      : 0;
  };

  // eslint-disable-next-line consistent-return
  const calculate = (fieldNameChanged, math) => {
    const valuesToOperate = [];
    const { operation, values } = math;
    // eslint-disable-next-line no-restricted-syntax
    for (const mathItem of values) {
      if (typeof mathItem === 'string') {
        if (operation === 'arrsum') {
          const [arrayItemsName] = mathItem.match(/^(\w+)(?=\[\])/);
          if (arrayItemsName) {
            const arrayItems = get(formik.values, arrayItemsName, []);
            if (arrayItems?.length) {
              for (const [arrayItemIndex] of arrayItems.entries()) {
                valuesToOperate.push(
                  getMathItemFieldValue(
                    mathItem,
                    mathItem.replace('[]', `.${arrayItemIndex}`),
                  ),
                );
              }
            }
          }
        } else {
          valuesToOperate.push(
            getMathItemFieldValue(mathItem, fieldNameChanged),
          );
        }
      } else if (typeof mathItem === 'number') {
        valuesToOperate.push(
          isNumberGreaterThanOrEqualZero(mathItem) ? mathItem : 0,
        );
      } else {
        valuesToOperate.push(parseFloat(calculate(fieldNameChanged, mathItem)));
      }
    }

    if (valuesToOperate.length >= values.length && operations?.[operation]) {
      const finalValue = parseFloat(operations?.[operation](valuesToOperate));

      if (isNumberGreaterThanOrEqualZero(finalValue)) {
        return Number(`${Math.round(`${finalValue}e2`)}e-2`);
      }
    }
    return 0;
  };

  useEffect(() => {
    if (component?.math && formik.values) {
      const diff = getObjectsDiff(
        prevValues || formik.values,
        prevValues ? formik.values : {},
      );

      // eslint-disable-next-line no-restricted-syntax
      for (const diffElement of diff) {
        const mathElementsName = diffElement.replace(/.\d./, '[].');
        const mathJson = JSON.stringify(component.math);

        if (mathJson.indexOf(mathElementsName) >= 0) {
          if (
            mathElementsName.includes('[]') &&
            /.(\d)./.test(currentFieldName) &&
            /.(\d)./.test(diffElement)
          ) {
            const [, componentIndex] = currentFieldName.match(/.(\d)./);
            const [, diffElementIndex] = diffElement.match(/.(\d)./);
            if (componentIndex === diffElementIndex) {
              formik.setFieldTouched(currentFieldName, true);
              formik.setFieldValue(
                currentFieldName,
                calculate(diffElement, component.math),
              );
            }
          } else {
            formik.setFieldTouched(currentFieldName, true);
            formik.setFieldValue(
              currentFieldName,
              calculate(diffElement, component.math),
            );
          }
        }
      }
    }
  }, [formik.values]);
};

export default usePageBuilderFieldMath;
