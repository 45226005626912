const onChangeFilterValidations = {
  number: (val = '') => val.replace(/[^0-9.]/g, ''),
  digits: (val = '') => val.replace(/[^0-9]/g, ''),
  alpha: (val = '') => val.replace(/[^a-zA-Z]/g, ''),
  alphanum: (val = '') => val.replace(/[^a-zA-Z0-9]/g, ''),
  maxLength: (length, val = '') => val.slice(0, length),
  zip: (val = '') => val.replace(/[^0-9]/g, '').slice(0, 5),
  firstName: (val = '') => val.replace(/[^a-zA-Z]/g, ''),
  lastName: (val = '') => val.replace(/[^a-zA-Z-]/g, ''),
};

const useOnChangeFilter = (component) => {
  const onChange = (value = '') => {
    let filterVal = value;
    if (component?.field?.validation?.length) {
      for (const validation of component.field.validation) {
        const valName = validation.val_key.replace(/\((.*)\)/, '');
        const validationFunc = onChangeFilterValidations?.[valName];

        if (validationFunc) {
          if (valName === 'maxLength') {
            const regex = /^\s*(\w+)\s*\((.*)\)/;
            const [, , length] = validation.val_key.match(regex) || [];
            if (length) {
              filterVal = validationFunc(parseInt(length, 10), filterVal);
            }
          } else {
            filterVal = validationFunc(filterVal);
          }
        }
      }
    }
    return filterVal;
  };

  return { onChange };
};

export default useOnChangeFilter;
