import { format, isValid } from 'date-fns';
import {
  numberWithCommas,
  replacePatternWithValues,
} from '../../../components/PageBuilder/helper';

const phoneFormat = (value) =>
  replacePatternWithValues(value, '(999)999-9999)');

const currencyFormat = (value) => `$${numberWithCommas(value)}`;

const accountNumberFormat = (value) => `XXXXXXXXXXXX${value.slice(-4)}`;

const residenceTypeFormat = (value) => {
  const types = {
    O: 'Own',
    R: 'Rent',
    OT: 'Other',
  };
  return types?.[value] || value;
};

const substringFormat = (value, indexStart, indexEnd) => {
  if (value) {
    return String(value).substring(indexStart, indexEnd);
  }
};

const dateFormat = (value, dateFormat = 'MM/dd/yyyy') => {
  try {
    if (value) {
      const date = new Date(value);
      if (!isValid(date)) {
        return value;
      }

      return format(date, dateFormat);
    }
  } catch (e) {
    return value;
  }
};

export default {
  phoneFormat,
  currencyFormat,
  accountNumberFormat,
  residenceTypeFormat,
  substringFormat,
  dateFormat,
};
