import { useRouter } from 'next/router';

const useNavigation = () => {
  const router = useRouter();
  const navigateTo = (url) => {
    if (url && typeof window !== 'undefined') {
      // eslint-disable-next-line no-undef
      window.location.replace(url);
    } else {
      router.replace(url);
    }
  };

  const reload = () => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-undef
      window.location.reload();
    } else {
      router.reload();
    }
  };

  return { navigateTo, reload };
};

export default useNavigation;
