import { get } from 'lodash';
import { useContext } from 'react';
import { DataContext } from '../../../components/layout/Default';
import formatLib from './lib';

const useSessionValueReplace = () => {
  const { sessionData } = useContext(DataContext);

  const replaceFunctionValue = (value) => {
    const [, functionName, stringParams = ''] = value.match(/(\w+)\(([^)]*)\)/);

    if (!formatLib?.[functionName] || !stringParams) {
      return '';
    }

    const functionParams = stringParams.split(',').map((param) => {
      const inputString = param.trim();
      if (inputString === 'true' || inputString === 'false') {
        return inputString === 'true';
      }

      if (
        !Number.isNaN(inputString) &&
        !Number.isNaN(parseFloat(inputString))
      ) {
        return parseFloat(inputString);
      }

      return inputString;
    });
    if (functionParams.length) {
      const sessionValue = get(sessionData, functionParams.shift(), '');

      if (!sessionValue) {
        return '';
      }

      return formatLib[functionName](sessionValue, ...functionParams);
    }

    return formatLib[functionName]();
  };

  const replaceSessionValues = (str = '') => {
    if (typeof str !== 'string') {
      return str;
    }

    return str.replace(/{([^}]+)}/g, (match, value) => {
      if (match && value) {
        if (/(\w+)\(([^)]*)\)/.test(value)) {
          return replaceFunctionValue(value);
        }

        const sessionValue = get(sessionData, value, '');
        return sessionValue ? String(sessionValue) : '';
      }

      return match;
    });
  };

  return {
    replaceSessionValues,
  };
};

export default useSessionValueReplace;
