import { useField, useFormikContext } from 'formik';
import { get, has } from 'lodash';
import customValidation from './commonValidations';
import useParseConditionalJson from '../useParseConditionalJson';

const defaultInputSchemas = {
  InputText: [customValidation.string],
  InputEmail: [customValidation.email],
  InputPhone: [customValidation.phone],
  InputSelect: [customValidation.mixed],
  InputNumber: [customValidation.number],
  InputCurrency: [customValidation.currency],
  InputSSN: [customValidation.ssn],
  InputDateGroup: [customValidation.string],
  InputDatePicker: [customValidation.string],
  InputCheckbox: [customValidation.mixed],
  InputRadio: [customValidation.mixed],
  InputHidden: [customValidation.mixed],
  InputSalesAssociateSelect: [customValidation.mixed],
  InputTextarea: [customValidation.string],
  InputFortivaOfferSelect: [customValidation.mixed],
};

function mergeSchemas(schemas) {
  const [first, ...rest] = schemas;

  try {
    return rest.reduce(
      (mergedSchemas, schema) => mergedSchemas.concat(schema),
      first,
    );
  } catch (e) {
    return schemas;
  }
}

function generateCustomValidationSchema(component) {
  const validationSchema = [];
  const [field, , fieldHelpers] = useField(
    component.value || component.field.value,
  );
  const formik = useFormikContext();
  if (component?.field?.validation?.length && field) {
    // eslint-disable-next-line no-restricted-syntax
    for (const validation of component.field.validation) {
      const replaceObj = {
        '{lender}': formik?.values?.customersession?.lenderName,
        '{label}': component?.label || component?.field?.label,
      };
      const replaceRegex = /\{lender}|\{label}/g;
      const message = validation.val_message.replace(
        replaceRegex,
        (match) => replaceObj?.[match],
      );
      const validationFunc =
        customValidation?.[validation.val_key.replace(/\((.*)\)/, '')];

      if (validationFunc) {
        const args = { formik };
        const regex = /^\s*(\w+)\s*\((.*)\)/; // get method name and params
        const [, , param] = validation.val_key.match(regex) || [];

        if (param) {
          if (has(formik.values, param)) {
            args.param = get(formik.values, param);
          } else {
            args.param = param;
          }
        }

        validationSchema.push(validationFunc(message, fieldHelpers, args));
      }
    }
  }
  return validationSchema;
}

const usePageBuilderFieldValidation = (component) => {
  const { isRequired } = useParseConditionalJson(component);

  let validationSchema = defaultInputSchemas[component.type][0];
  if (typeof defaultInputSchemas?.[component.type]?.[0] === 'function') {
    validationSchema = mergeSchemas([defaultInputSchemas[component.type][0]()]);
  } else {
    validationSchema = mergeSchemas([...defaultInputSchemas[component.type]]);
  }

  if (component?.required) {
    const required = isRequired();

    if (required) {
      if (component.type === 'InputCheckbox') {
        const oneOfValues = (
          component.properties ||
          component?.field?.properties ||
          []
        ).map(({ value }) => value || 'true');
        validationSchema = mergeSchemas([
          validationSchema,
          validationSchema.oneOf(oneOfValues, 'User must agree to proceed'),
        ]);
      } else {
        validationSchema = mergeSchemas([
          validationSchema,
          validationSchema.nullable().required('Field is required'),
        ]);
      }
    }
  }

  if (component?.field?.validation?.length) {
    const customValidationSchema = generateCustomValidationSchema(component);
    if (customValidationSchema?.length) {
      validationSchema = mergeSchemas([
        validationSchema,
        ...customValidationSchema,
      ]);
    }
  }

  const validate = async (value) => {
    if (validationSchema) {
      try {
        await validationSchema.validate(value);
      } catch (e) {
        return e.message;
      }
    }

    return null;
  };

  return { validate };
};

export default usePageBuilderFieldValidation;
