// eslint-disable-next-line prefer-regex-literals
export const specialChars = new RegExp("[%^*()_+=@$!\\;,{}[]'`?><~|&\\/-]");

export const valAddressReplace = (val) => {
  let newVal = val;
  newVal = ` ${newVal} `;
  newVal = newVal.replace(specialChars, '');
  newVal = newVal.replace(' TRLR ', ' TRAILER ');
  newVal = newVal.replace(' BIX ', ' BOX ');
  newVal = newVal.replace(' BX ', ' BOX ');
  newVal = newVal.replace(' DRAWER ', ' PO BOX ');
  newVal = newVal.replace(' CALLER ', ' PO BOX ');
  newVal = newVal.replace(' CALLER BOX ', ' PO BOX ');
  newVal = newVal.replace(' LOCKBOX ', ' PO BOX ');
  newVal = newVal.replace(' LOCK BOX ', ' PO BOX ');
  newVal = newVal.replace(' POBOX ', ' PO BOX ');
  newVal = newVal.replace(' POST OFFICE BOX ', ' PO BOX ');
  newVal = newVal.replace(' P/O ', ' PO BOX ');
  newVal = newVal.replace(' P O ', ' PO BOX ');
  newVal = newVal.replace(' BOX BOX ', ' BOX ');
  newVal = newVal.replace(' PO PO ', ' PO BOX ');
  newVal = newVal.replace(' GEN DELV ', ' GENERAL DELIVERY ');
  newVal = newVal.replace(' GEN DELIVERY ', ' GENERAL DELIVERY ');
  newVal = newVal.replace(' GENERAL DELV ', ' GENERAL DELIVERY ');
  newVal = newVal.replace(' GNRL DELV ', ' GENERAL DELIVERY ');
  newVal = newVal.replace(' GEN DLVRY ', ' GENERAL DELIVERY ');
  newVal = newVal.replace(' NORTH ', ' N ');
  newVal = newVal.replace(' NO ', ' N ');
  newVal = newVal.replace(' NOR ', ' N ');
  newVal = newVal.replace(' NORT ', ' N ');
  newVal = newVal.replace(' NORTHEAST ', ' NE ');
  newVal = newVal.replace(' NOEAST ', ' NE ');
  newVal = newVal.replace(' NEAST ', ' NE ');
  newVal = newVal.replace(' NOREAST ', ' NE ');
  newVal = newVal.replace(' N E ', ' NE ');
  newVal = newVal.replace(' NORTHWEST ', ' NW ');
  newVal = newVal.replace(' NOWEST ', ' NW ');
  newVal = newVal.replace(' NWEST ', ' NW ');
  newVal = newVal.replace(' NORWEST ', ' NW ');
  newVal = newVal.replace(' N W ', ' NW ');
  newVal = newVal.replace(' SOUTH ', ' S ');
  newVal = newVal.replace(' SO ', ' S ');
  newVal = newVal.replace(' SOU ', ' S ');
  newVal = newVal.replace(' SOUT ', ' S ');
  newVal = newVal.replace(' SOUTHEAST ', ' SE ');
  newVal = newVal.replace(' SOEAST ', ' SE ');
  newVal = newVal.replace(' SEAST ', ' SE ');
  newVal = newVal.replace(' SOUEAST ', ' SE ');
  newVal = newVal.replace(' S E ', ' SE ');
  newVal = newVal.replace(' SOUTHWEAST ', ' SW ');
  newVal = newVal.replace(' SOWEST ', ' SW ');
  newVal = newVal.replace(' SWEST ', ' SW ');
  newVal = newVal.replace(' SOUWEST ', ' SW ');
  newVal = newVal.replace(' S W ', ' SW ');
  newVal = newVal.replace(' EAST ', ' E ');
  newVal = newVal.replace(' WEST ', ' W ');
  newVal = newVal.replace(' WST ', ' W ');
  newVal = newVal.replace(' R ROUTE ', ' RR ');
  newVal = newVal.replace(' R ', ' RR ');
  newVal = newVal.replace(' R R ', ' RR ');
  newVal = newVal.replace(' R RT ', ' RR ');
  newVal = newVal.replace(' RT ', ' RR ');
  newVal = newVal.replace(' RFD ', ' RR ');
  newVal = newVal.replace(' RFD RT ', ' RR ');
  newVal = newVal.replace(' RFD ROUTE ', ' RR ');
  newVal = newVal.replace(' RTE ', ' RR ');
  newVal = newVal.replace(' RR BOX ', ' RR ');
  newVal = newVal.replace(' RURAL ROUTE ', ' RR ');
  newVal = newVal.replace(' RRT ', ' RR ');
  newVal = newVal.replace(' R F D ', ' RR ');
  newVal = newVal.replace(' STAR ROUTE ', ' HC ');
  newVal = newVal.replace(' STAR RT ', ' HC ');
  newVal = newVal.replace(' STAR RR ', ' HC ');
  newVal = newVal.replace(' STAR RTE ', ' HC ');
  newVal = newVal.replace(' H C ', ' HC ');
  newVal = newVal.replace(' STREET ', ' ST ');
  newVal = newVal.replace(' STR ', ' ST ');
  newVal = newVal.replace(' STRE ', ' ST ');
  newVal = newVal.replace(' STREE ', ' ST ');
  newVal = newVal.replace(' AV ', ' AVE ');
  newVal = newVal.replace(' AVEN ', ' AVE ');
  newVal = newVal.replace(' AVENU ', ' AVE ');
  newVal = newVal.replace(' AVENUE ', ' AVE ');
  newVal = newVal.replace(' BRIDGE ', ' BRG ');
  newVal = newVal.replace(' BOULEVARD ', ' BLVD ');
  newVal = newVal.replace(' BL ', ' BLVD ');
  newVal = newVal.replace(' BRD ', ' BLVD ');
  newVal = newVal.replace(' DRIVE ', ' DR ');
  newVal = newVal.replace(' DRV ', ' DR ');
  newVal = newVal.replace(' ROAD ', ' RD ');
  newVal = newVal.replace(' TERR ', ' TER ');
  newVal = newVal.replace(' TERRACE ', ' TER ');
  newVal = newVal.replace(' TE ', ' TER ');
  newVal = newVal.replace(' PKY ', ' PRWY ');
  newVal = newVal.replace(' PARKWAY ', ' PRWY ');
  newVal = newVal.replace(' PARK WAY ', ' PRWY ');
  newVal = newVal.replace(' RADIAL ', ' RADL ');
  newVal = newVal.replace(' HIGHWAY ', ' HWY ');
  newVal = newVal.replace(' JUNCTION ', ' JCT ');
  newVal = newVal.replace(' JUNCT ', ' JCT ');
  newVal = newVal.replace(' HEIGHTS ', ' HTS ');
  newVal = newVal.replace(' BYPASS ', ' BYP ');
  newVal = newVal.replace(' BY PASS ', ' BYP ');
  newVal = newVal.replace(' EXPRESS WAY ', ' EXPY ');
  newVal = newVal.replace(' EXPRESSWAY ', ' EXPY ');
  newVal = newVal.replace(' EXPWY ', ' EXPY ');
  newVal = newVal.replace(' POINT ', ' PT ');
  newVal = newVal.replace(' PLACE ', ' PL ');
  newVal = newVal.replace(' TFWY ', ' TRFY ');
  newVal = newVal.replace(' TFCWY ', ' TRFY ');
  newVal = newVal.replace(' TRAFFIC WAY ', ' TRFY ');
  newVal = newVal.replace(' TRAFFICWAY ', ' TRFY ');
  newVal = newVal.replace(' RIDGE ', ' RDG ');
  newVal = newVal.replace(' APTS ', ' APT ');
  newVal = newVal.replace(' APARTMENT ', ' APT ');
  newVal = newVal.replace(' APARTMENTS ', ' APT ');
  newVal = newVal.replace(' APART ', ' APT ');
  newVal = newVal.replace(' SQUARE ', ' SQ ');
  newVal = newVal.replace(' SQUAR ', ' SQ ');
  newVal = newVal.replace(' LANE ', ' LN ');
  newVal = newVal.replace(' CI ', ' CIR ');
  newVal = newVal.replace(' CR ', ' CIR ');
  newVal = newVal.replace(' CIRC ', ' CIR ');
  newVal = newVal.replace(' CIRCL ', ' CIR ');
  newVal = newVal.replace(' CIRCLE ', ' CIR ');
  newVal = newVal.replace(' PLAZA ', ' PLZ ');
  newVal = newVal.replace(' PLAZ ', ' PLZ ');
  newVal = newVal.replace(' PZ ', ' PLZ ');
  newVal = newVal.replace(' COURT ', ' CT ');
  newVal = newVal.replace(' CRT ', ' CT ');
  newVal = newVal.replace(' GARDENS ', ' GDNS ');
  newVal = newVal.replace(' SUITE ', ' STE ');
  newVal = newVal.replace(' BUILDING ', ' BLDG ');
  newVal = newVal.replace(' ROOM ', ' RM ');
  newVal = newVal.replace(' DEPARTMENT ', ' DEPT ');
  newVal = newVal.replace(' FLOOR ', ' FL ');
  newVal = newVal.substring(1, newVal.length - 1);
  return newVal;
};
