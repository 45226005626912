const stringMatch = (fieldVal, valueVal) => {
  const fieldValStr = String(fieldVal);
  const valueValStr = String(valueVal);

  return (
    fieldValStr.includes(valueValStr) &&
    fieldValStr.length === valueValStr.length
  );
};

const stringNomatch = (fieldVal, valueVal) => !fieldVal.includes(valueVal);

const empty = (fieldVal) => fieldVal === null || fieldVal === '';

const notEmpty = (fieldVal) => fieldVal !== null && fieldVal !== '';

const equals = (fieldVal, valueVal) =>
  parseFloat(fieldVal) === parseFloat(valueVal);

const gt = (fieldVal, valueVal) => parseFloat(fieldVal) > parseFloat(valueVal);

const lt = (fieldVal, valueVal) => parseFloat(fieldVal) < parseFloat(valueVal);

const gte = (fieldVal, valueVal) =>
  parseFloat(fieldVal) >= parseFloat(valueVal);

const lte = (fieldVal, valueVal) =>
  parseFloat(fieldVal) <= parseFloat(valueVal);

const regexNomatch = (fieldVal, valueVal) => {
  const regex = new RegExp(valueVal);
  return !regex.test(fieldVal);
};

const regexMatch = (fieldVal, valueVal) => {
  const regex = new RegExp(valueVal);
  return regex.test(fieldVal);
};

const inValArray = (fieldVal, valueVal) => valueVal.includes(fieldVal);
const notInValArray = (fieldVal, valueVal) => !valueVal.includes(fieldVal);

export const comparisonTypesFunction = {
  string_match: stringMatch,
  string_nomatch: stringNomatch,
  empty,
  not_empty: notEmpty,
  equals,
  gt,
  lt,
  gte,
  lte,
  regex_match: regexMatch,
  regex_nomatch: regexNomatch,
  in: inValArray,
  not_in: notInValArray,
};
